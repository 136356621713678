import {MDBCardFooter} from "mdb-react-ui-kit";
import React from "react";
import {useAppDispatch} from "../store/hooks";
import {setUserQuery} from "../store/features/chatRequest/chatRequestSlice";
import {asyncSendChatMessage} from "../store/features/chat/chatSlice";
import InputWithIcon from "./generic/InputWithIcon";
import "./ChatFooter.css"

function ChatFooter() {
    const dispatch = useAppDispatch();

    function askChatAdvisor(query: string) {
        dispatch(setUserQuery(query));
        dispatch(asyncSendChatMessage())
    }

    return <MDBCardFooter className="bg-primary chat-footer">
        <InputWithIcon onSubmit={askChatAdvisor}>
            <img src="/icons/arrow.svg" alt="Submit"/>
        </InputWithIcon>
    </MDBCardFooter>
}

export default ChatFooter