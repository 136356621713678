import {configureStore} from '@reduxjs/toolkit'
import chatReducer from "./features/chat/chatSlice";
import chatRequestReducer from "./features/chatRequest/chatRequestSlice";

export const store = configureStore({
    reducer: {
        chat: chatReducer,
        chatRequest: chatRequestReducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch