import {ReactNode} from "react";

type LinkifyProperties = {
    children: ReactNode
}

function Linkify(props: LinkifyProperties) {
    const isUrl = (word: string) => {
        const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^.]*)?$/gm;
        return word.match(urlPattern)
    }

    const addMarkup = (word: string) => {
        //TODO - obsługa znaków specjalnych na końcu - kropka, przecinek
        if (word.endsWith(".")) {
            word = word.slice(0, -1)
            return isUrl(word) ?
                `<a href="${word}" target="_blank">${word}</a>.` :
                word + "."
        } else {
            return isUrl(word) ?
                `<a href="${word}" target="_blank">${word}</a>` :
                word
        }

    }

    const words = props.children!.toString().split(' ')
    const formatedWords = words.map((w: string) => addMarkup(w))
    const html = formatedWords.join(' ')
    return (<span dangerouslySetInnerHTML={{__html: html}}/>)
}

export default Linkify;