import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getChatConfig, sendChatMessage} from "./api";
import {AppDispatch, RootState} from "../../store";
import {setChatbotId} from "../chatRequest/chatRequestSlice";
import i18n from "../../../i18n";

export interface ChatState {
    messages: ChatEntry[],
    status: 'idle' | 'pending' | 'error',
    active: boolean
    config: ChatConfig | undefined
}

const initialState: ChatState = {
    messages: [],
    status: "idle",
    active: false,
    config: undefined
}

function mapHistory(messages: ChatEntry[]): any[] {
    let history = []
    let aiMessage = "";
    let userMessage = "";
    for (const chat of messages) {
        if (chat.role === "AI") {
            aiMessage = chat.message
            history.push({user_input: userMessage, ai_output: aiMessage})
        } else {
            userMessage = chat.message
        }
    }
    return history;
}

function buildMessageChatEntry(state: RootState): ChatRequestEntry {

    return {
        role: "User",
        message: state.chatRequest.userQuery,
        chatbotId: state.chatRequest.chatbotId,
        history: mapHistory(state.chat.messages),
    }
}

export const asyncSendChatMessage =
    createAsyncThunk<void, void, {
        dispatch: AppDispatch,
        state: RootState
    }>('chat/sendMessage', async (v, thunkAPI) => {
        let message = buildMessageChatEntry(thunkAPI.getState())
        thunkAPI.dispatch(addMessage({message: thunkAPI.getState().chatRequest.userQuery, role: "User"}))
        const response = await sendChatMessage(message);
        const payload = {
            role: response.role,
            message: response.message,
        } as ChatEntry;

        thunkAPI.dispatch(addMessage(payload))
    })


export const loadChatConfig =
    createAsyncThunk<void, string, {
        dispatch: AppDispatch,
        state: RootState
    }>('chat/loadConfig', async (chatbotId: string, thunkAPI) => {
        const response = await getChatConfig(chatbotId);
        thunkAPI.dispatch(setConfig(response))
        thunkAPI.dispatch(setChatbotId(chatbotId))
        if (thunkAPI.getState().chat.messages.length === 0) {
            const payload = {
                role: "AI",
                message: response.greeting.trim() !== "" ? response.greeting : i18n.t("chat.welcome_message"),
            } as ChatEntry;
            thunkAPI.dispatch(addMessage(payload))
        }
    })


export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<ChatEntry>) => {
            state.messages.push(action.payload)
        },
        setActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload
        },
        setConfig: (state, action: PayloadAction<ChatConfig>) => {
            state.config = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncSendChatMessage.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(asyncSendChatMessage.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(asyncSendChatMessage.rejected, (state) => {
                addMessage({message: "Wystąpił błąd", role: "AI"})
                state.status = 'idle';
            })
            .addCase(loadChatConfig.pending, (state) => {
                if (state.messages.length === 0) {
                    state.status = 'pending';
                }
            })
            .addCase(loadChatConfig.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(loadChatConfig.rejected, (state) => {
                addMessage({message: "Wystąpił błąd", role: "AI"})
                state.status = 'idle';
            })
    }
})

export const {addMessage, setActive, setConfig} = chatSlice.actions;

export default chatSlice.reducer;