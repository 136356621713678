import React, {ReactNode, useRef, useState} from "react";
import "./InputWithIcon.scss"
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

type InputWithIconProperties = {
    children: ReactNode,
    onSubmit: (value: string) => void
}

function InputWithIcon(props: InputWithIconProperties) {
    const [isEmpty, setEmpty] = useState(true)
    const isPending = useSelector((state: RootState) => state.chat.status === 'pending');
    const ref = useRef<HTMLDivElement>(null);

    function inputKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === 'Enter') {
            onClick(e)
            e.preventDefault()
        }
    }

    function updateContent(e: React.FormEvent<HTMLDivElement>) {
        if (e.currentTarget.textContent && isEmpty) {
            setEmpty(false);
        } else if (!e.currentTarget.textContent) {
            setEmpty(true);
        }
    }

    function onClick(e: React.FormEvent<HTMLElement>) {
        if (ref.current!.textContent && !isPending) {
            props.onSubmit(ref.current!.textContent);
            ref.current!.textContent = ""
        }
    }

    return <div className="input-group">
        <div className="text-box form-control text-sm-start input-bar align-items-center d-flex">
            <div ref={ref} className="col-12" onKeyDown={(e) => inputKeyDown(e)}
                 onInput={(e) => updateContent(e)}
                 contentEditable="true"></div>
        </div>
        <button className="btn btn-with-icon" type="button"
                onClick={(e) => onClick(e)}>{props.children}</button>
    </div>
}

export default InputWithIcon;