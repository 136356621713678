import React from "react";
import "./App.scss";
import Chat from "./component/Chat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch} from "./store/hooks";
import {loadChatConfig, setActive} from "./store/features/chat/chatSlice";
import {useSelector} from "react-redux";
import {RootState} from "./store/store";

export interface ChatMessage {
    role: string;
    message: string;
}

function App() {

    const dispatch = useAppDispatch();
    const isActive = useSelector((state: RootState) => state.chat.active);


    React.useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'ChatAction') {
                if (event.data.open === true) {
                    dispatch(loadChatConfig(event.data.chatbotId))
                }
                dispatch(setActive(event.data.open));
            }
        });
    });

    function toggleChat() {
        dispatch(setActive(!isActive))
    }

    return (
        <main>
            <div className={`floating-icon ${isActive ? "open" : ""}`} onClick={toggleChat}>
                {!isActive ?
                    <div className="avatar-closed">
                        <div className="icon-text">AI</div>
                    </div>
                    :
                    <div className="avatar-open">
                        <FontAwesomeIcon icon={faXmark} size={"2x"}
                                         aria-hidden={true}/>
                    </div>
                }
            </div>
            <div className={`chat-background bg-black ${isActive ? "open" : ""}`}/>
            <div className={`chat-window ${isActive ? "open" : ""}`}>
                <Chat/>
            </div>
        </main>
    );
}

export default App;
