export async function sendChatMessage(message: ChatRequestEntry): Promise<ChatResponse> {
    return await fetch("/api/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            role: message.role,
            message: message.message,
            chatbot_id: message.chatbotId,
            history: message.history
        }),
    })
        .then((response) => response.json())
}

export async function getChatConfig(chatbotId: string): Promise<ChatConfig> {
    return await fetch("/api/chat/" + chatbotId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
}